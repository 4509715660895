import apis from '../utils/apis'
import { Dialog, Toast, Image, CountDown, DropdownMenu, DropdownItem, PullRefresh, List, Overlay, Field, NavBar, Icon, Rate, Popup, Tag, } from 'vant';
export default {
  name: 'retailOrderDetail',
  components: {
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
    'van-pull-refresh': PullRefresh,
    'van-list': List,
    'van-overlay': Overlay,
    'van-icon': Icon,
    'van-rate': Rate,
    'van-field': Field,
    'van-image': Image,
    'van-count-down': CountDown,
    "van-popup": Popup,
    'van-tag': Tag,
    'van-nav-bar': NavBar,
  },
  data () {
    return {
      order: {},
      orderDetails: {},
      orderRefund:{},
      code: this.$route.query.orderNumber,
      orderType: 1,
      time: 0,
      isCutDown: true,//是否结束支付倒计时
      isRefund: false,//是否可以退款 待发货4、待收货5、已完成7
    }
  },
  methods: {
    //订单详情done
    load () {
      this.$get3({
        url: apis.findOrder + this.$route.query.orderId,
      }).then(res => {
        this.order = res.data.order
        let discountAmount = 0
        if (this.order.discountAmount) {
          discountAmount = this.order.discountAmount
        }
        this.order.startPayTime = this.format(this.order.startPayTime)
        if (res.data.orderDetails.length > 0) {
          this.orderDetails = res.data.orderDetails[0]
        }
        this.orderRefund = res.data.orderRefund?res.data.orderRefund:{}
        console.log()

        //是否可以退款 待发货4、待收货5、已完成7
        if (this.order.orderStatus == 4 || this.order.orderStatus == 5 || this.order.orderStatus == 7) {
          if (this.orderDetails.orderDetail.refundStatus === null || this.orderDetails.orderDetail.refundStatus === 0) {
            this.isRefund = true
          }
        }
        //付款时间
        let now = new Date().getTime();
        let cutDownTime = new Date(this.$renderTime(this.order.creatorTime)).getTime();
        if (cutDownTime + 15 * 60 * 1000 > now) {
          this.time = cutDownTime + 15 * 60 * 1000 - now;
        } else {
          this.isCutDown = false;
        }
      });
    },
    //去支付
    gotoPay () {
      this.$router.push({
        path: '/goodsPay',
        query: {
          orderId: this.order.orderId,
        }
      });
    },
    //再次预定
    gotoDetail () {
      this.$router.push({
        path: '/goodsInfo/',
        query: {
          commodityId: this.orderDetails.commodity.commodityId,
        },
      });
    },
    //取消订单
    cancelOrder () {
      let me = this;
      me.$get({
        url: apis.cancelOrder,
        params: {
          orderId: me.order.orderId,
          token: me.$global.token,
        },
      }).then(res => {
        if (res.status == 1) {
          Toast('取消成功');
          me.load();
        } else {
          Toast(res.message);
        }
      }).catch(err => {
        Toast(err.message);

      });
    },
    refundPay () {
      let me = this;
      Dialog.confirm({
        cancelButtonText: '我再想想',
        message: '确定申请退款吗？',
      }).then(() => {
        me.$post({
          url: apis.refundApplyRetail,
          params: {
            orderId: me.order.orderId,
            token: me.$global.token,
          },
        }).then(res => {
          Toast('申请退款成功');
          this.order = {}
          this.orderDetails = {}
          this.isRefund = false
          me.load();
        });
      })
        .catch(() => { });
    },
    
    //撤回申请
    recallOrder () {
      let _t = this;
      Dialog.confirm({
        cancelButtonText: '我再想想',
        message: '确定撤销申请吗？',
      })
        .then(() => {
          _t.$post({
            url: apis.revokeRefundApply,
            params: {
              token: _t.$global.token,
              orderNumber: this.order.orderNumber,
            }
          }).then(res => {
            Toast('操作成功');
            _t.load();
          }).catch(err => {
            Toast(err.message);
          })
        })
        .catch(() => {
          // on cancel
        });
    },

//去评价
goEvaluate(){
  this.$router.push({
    path: '/skiEvaluate/',
    query: {
      orderId: this.order.orderId,
    },
  });
},
    //订单状态文字
    getStatusText (row) {
      console.log('订单状态文字')

      console.log(row)
      let statusArr = [
        { name: '未支付', color: 'green', num: 0 }, //1
        { name: '待支付', color: 'green', num: 1 }, //2
        { name: '已取消', color: 'dark', num: 2 }, //3
        { name: '支付失败', color: 'dark', num: 3 }, //4
        { name: '待发货', color: 'orange', num: 4 }, //5
        { name: '待收货', color: 'green', num: 5 }, //6
        { name: '发货失败', color: 'dark', num: 6 }, //7
        { name: '已完成', color: 'green', num: 7 }, //8
        { name: '订单关闭', color: 'dark', num: 8 }, //9

        { name: '退款审核中...', color: 'green', num: 9 }, //10
        { name: '退票中...', color: 'dark', num: 10 }, //11
        { name: '退票失败', color: 'dark', num: 11 }, //12
        { name: '退款中', color: 'green', num: 12 }, //12
        { name: '退款失败', color: 'dark', num: 13 }, //13
        { name: '退款成功', color: 'green', num: 14 }, //14
        { name: '财务审核中', color: 'green', num: 15 }, //15
        { name: '退款失败', color: 'dark', num: 16 }, //15
      ];
      let num = 0;
      if (this.orderRefund.status != undefined) {
        let a = this.orderRefund
        console.log(this.orderRefund.status)
        if (a.status == 1) {
          num = 9;
        }
        if (a.status == 2) {
          num = 10;
        }
        if (a.status == 3) {
          num = 11;
        }
        if (a.status == 4) {
          num = 12;
        }
        if (a.status == 5) {
          num = 13;
        }
        if (a.status == 6) {
          num = 14;
        }
        if (a.status == 7) {
          num = 15;
        }
        if (a.status == 8) {
          num = 16;
        }
      } else {
        num = row.orderStatus
      }
      return statusArr[num];
    },
    //格式化时间
    format (dat) {
      if (dat) {
        //获取年月日，时间
        dat = new Date(dat)
        let year = dat.getFullYear();
        let mon = (dat.getMonth() + 1) < 10 ? "0" + (dat.getMonth() + 1) : dat.getMonth() + 1;
        let data = dat.getDate() < 10 ? "0" + (dat.getDate()) : dat.getDate();
        let hour = dat.getHours() < 10 ? "0" + (dat.getHours()) : dat.getHours();
        let min = dat.getMinutes() < 10 ? "0" + (dat.getMinutes()) : dat.getMinutes();
        let seon = dat.getSeconds() < 10 ? "0" + (dat.getSeconds()) : dat.getSeconds();

        let newDate = year + "-" + mon + "-" + data + " " + hour + ":" + min + ":" + seon;
        return newDate;
      } else {
        return null
      }
    },
    back () {
      this.$router.go(-1)
    },
  },
  created () {
    this.load()
  },
  mounted () {
  },
}