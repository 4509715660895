<template>
  <div class="container">
    <!-- 顶部 -->
    <van-nav-bar title="填写订单" @click-left="back()">
      <template #left>
        <van-icon color="#333333" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="main" v-if="order.orderNumber">
      <div class="staus box">
        <h2>订单状态</h2>
        <template v-if="orderRefund.status">
          <span :class="getStatusText(orderRefund).color ? getStatusText(orderRefund).color : ''">{{getStatusText(orderRefund).name}}</span>
        </template>
        <span v-else :class="getStatusText(order).color ? getStatusText(order).color : ''">{{getStatusText(order).name}}</span>
      </div>
      <div class="time d_f ali_c box" v-if="getStatusText(order).num == 0 || getStatusText(order).num == 1">
        <img src="../../home/order/img/od_clark.png" alt="">
        <template v-if="isCutDown">
          <span>支付剩余时间</span>
          <van-count-down class="time_num" :time="time" />
          <span v-if="isCutDown">请尽快支付</span>
        </template>
        <span v-else>订单已超时，请重新下单~</span>
      </div>
      <div class="box" v-else>
        <span v-if="getStatusText(order).num == 2 && order.closeType == 1">您的订单已取消，欢迎再次使用智游乐山。</span>
        <span v-if="getStatusText(order).num == 2 && order.closeType == 3">您的订单由于超时未支付已自动取消，欢迎再次使用智游乐山。</span>
        <span v-if="getStatusText(order).num == 4">购买成功，祝您旅途愉快。</span>
        <span v-if="getStatusText(order).num == 7">购买体验如何，立即前往评价，欢迎再次使用智游乐山。</span>
        <span v-if="getStatusText(order).num == 6">系统出票失败，退款将在1~3个工作日内园路退回至您的账户。</span>
        <span v-if="getStatusText(order).num == 9">退款申请审核中，审核后将发送短信至您的手机，请注意查收。</span>
        <span v-if="getStatusText(order).num == 14">退款处理成功，已退回原支付账户。</span>
        <span v-if="getStatusText(order).num == 16">退款失败，请查看退款进度了解退款详情，若有疑问请联系客服。</span>
      </div>
      <!-- 用户信息 -->
      <div class="user box">
        <h2>收货人信息</h2>
        <van-field readonly v-model="order.consignee" label="收货人" />
        <van-field readonly v-model="order.consigneePhone" label="手机号码" />
        <van-field readonly v-model="order.consigneeArea" label="地区选择" />
        <van-field readonly v-model="order.consigneeAddr" rows="1" autosize label="详细地址" type="textarea" />
      </div>
      <!-- 商品信息 -->
      <div class="goods box" v-if="orderDetails.commodity">
        <h2>商品信息</h2>
        <div class="row">
          <van-image :src="orderDetails.commodity.cover" width="100" height="100" class="goods_img" fit="cover">
            <template v-slot:error>加载失败</template>
          </van-image>
          <div class="goods_info">
            <h4>{{orderDetails.commodity.name}}</h4>
            <div class="ll">
              <span><sub>￥</sub>{{orderDetails.commodity.sellPrice?orderDetails.commodity.sellPrice/100:0}}</span>
              <van-tag v-if='order.discountAmount' round plain color="#FF3E6E" text-color="#FF3E6E">消费劵 抵扣{{order.discountAmount?order.discountAmount/100:0}}</van-tag>
              <span>数量 x {{orderDetails.orderDetail.quantity}}</span>
            </div>
            <h4>实付款：￥{{order.totalSellPrice?order.totalSellPrice/100:0}}</h4>
          </div>

        </div>
      </div>
      <div class="order box">
        <h2>订单信息</h2>
        <van-field readonly v-model="order.orderNumber" label="订单编号" />
        <van-field readonly v-model="order.creatorTime" label="下单时间" />
        <van-field readonly v-model="order.startPayTime" label="支付方式" />
        <van-field readonly v-model="order.startPayTime" label="支付时间" />
      </div>
      <div class="od_btn p_f d_f ali_c fd_rr">
        <div class="od_btn_out cur_p" @click="cancelOrder" v-if="order.orderStatus == 0 || order.orderStatus==1">取消</div>
        <div class="od_btn_out cur_p" @click="goEvaluate" v-if="order.orderStatus == 7">去评价</div>
        <div class="od_btn_out cur_p again" @click="gotoDetail">再次购买</div>
        <div class="od_btn_out cur_p" @click.stop="refundPay" v-if="isRefund">申请退款</div>
        <template v-if="orderRefund != undefined && orderRefund != null">
        <div class="od_btn_out cur_p"  @click.stop="recallOrder" v-if="orderRefund.status == 1">撤销申请</div>
        </template>
        <div class="od_btn_out cur_p pay" @click="gotoPay" v-if="(order.orderStatus == 0 || order.orderStatus==1)&&isCutDown">去支付</div>
      </div>
    </div>
  </div>
</template>
<script src='./retailOrderDetail.js'></script>
<style lang="scss" scoped src='./retailOrderDetail.scss'></style>
